body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto Condensed', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Center {
  text-align: center;
}

.add-button {
  background-color: #f7b446;
  border: none;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  width: 32px;
  height: 32px;
  font-size: 1.5rem;
  padding-left: 10px;
}

.add-button:hover {
  transform: translateY(-5px);
  background-color: #e6a33c;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
  transform-origin: center;
  animation: App-logo-spin 1s ease;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: start;
  justify-content: start;
  flex-wrap: nowrap;
  font-size: calc(6px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.move-left-right {
  animation: move-left-right 0.3s ease;
}

.move-up-down {
  animation: move-up-down 0.3s ease;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  flex-grow: 1;
  max-width: 50%;
  box-sizing: border-box;
  background-color: #24272e;
  border: 5px solid #282c34;
  margin-left: 10px;
  margin-right: 10px;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
  border: 5px solid #282c34;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
  background-color: #24272e;
  border: 5px solid #282c34;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
  /* border: 5px solid #282c34; */
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
  border: 5px solid #282c34;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
  border: 5px solid #282c34;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
  border: 5px solid #282c34;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
  border: 5px solid #282c34;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
  border: 5px solid #282c34;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
  /* border: 5px solid #282c34; */
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
  border: 5px solid #282c34;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
  border: 5px solid #282c34;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
  border: 5px solid #282c34;
}

.center-horizontal-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* height: 100vh; */
}

.center-vertical-wrapper {
    display: flex;
    height: 100vh;
}

.close-button {
  position: absolute;
  top: 130px;
  right: 10px;
  background-color: #f7b446;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  opacity: 0.6;
}

.close-button:hover {
  opacity: 1;
}

.close-icon {
  display: block;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23ffffff" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>') no-repeat center center;
  background-size: 24px 24px;
}

.menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-content: center;
    align-items: start;
    position: relative;
    margin-top: 100px;
    width: 1050px;
    padding: 20px;
    gap: 20px; /* Zorgt voor ruimte tussen de tegels */
}

.menu-tile {
    background-color: #f7b446; /* Doornenbal geel */
    color: white;
    padding: 20px;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px; /* Ronde hoeken */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    font-size: 20px;
    font-family: 'Roboto Condensed',Helvetica,Arial,Lucida,sans-serif;
    font-weight: normal;
    cursor: pointer;
    flex: 0 0 150px; /* flex-grow, flex-shrink, flex-basis */
}


.menu-tile:hover {
    transform: translateY(-5px);
    background-color: #e6a33c;
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4);
}

.banner {
    display: flex;
    align-items: center; /* Centreert de items verticaal */
    background-color: #f7b446;
    /* background-color: #2F2F2F; */
    padding: 10px 20px; /* Voegt wat ruimte toe binnen de banner */
    height: 100px; /* Of elke gewenste hoogte */
    z-index: 1000; /* Een hoge waarde om ervoor te zorgen dat het boven andere elementen komt */
    position: relative; /* Z-index werkt alleen op positioneerde elementen */
}

.banner-logo {
    height: 80px; /* Stelt de hoogte van het logo in, breedte wordt automatisch geschaald */
    margin-right: 20px; /* Voegt ruimte toe aan de rechterkant van het logo */
}

.banner-text {
    color: #5A5A5A; /* Stelt de tekstkleur in, kies een kleur die goed leesbaar is op de achtergrond */
    /* font-family: 'Roboto Condensed', sans-serif; */
    font-family: calibri;
    align-self: center;
    font-weight: 900;
}

.banner-text h1 {
    font-size: 32px; /* Grootte van de bedrijfsnaam */
    margin: 0; /* Verwijdert de standaard marge */
}

.banner-text p {
    font-size: 16px; /* Grootte van de slogan */
    margin: 0; /* Verwijdert de standaard marge */
}

.form-wrapper {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.wide-form-wrapper {
  max-width: 90%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* flex: auto; */
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-right: 1rem;
    /* flex: 1 1 400px; */
    max-width: 400px;
}

.form-input {
  /* max-width: 30%; */
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.form-input:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}

.form-button {
  padding: 0.8rem 1.2rem;
  background-color: #f7b446;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #e6a33c;
}

.form-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-select {
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
  width: auto; /* Dit kan aangepast worden afhankelijk van de breedte die je wilt instellen */
}

.form-select:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}

.form-textarea {
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%; /* Volledige breedte binnen zijn container */
  height: 150px; /* Standaard hoogte die aangepast kan worden naar behoefte */
  resize: vertical; /* Gebruikers kunnen de grootte verticaal aanpassen */
}

.form-textarea:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}

.split-view-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 100px); /* Trek de hoogte van de banner eraf */
    background-color: #f8f9fa; /* Achtergrondkleur van de hoofdcontainer */
}

.search-input {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px; /* Ruimte onder de zoekbalk */
  border-radius: 5px;
  border: 1px solid #ccc;
}
.list-wrapper {
  width: 12%;
  height: 100%;
  flex-direction: column;
  /* border-right: 10px solid #444; */
}
.list {
    width: 90%;
    height: 45%;
    /* color: black; */
    overflow-y: auto; /* Voeg scroll toe als de lijst te lang is */
    border-right: 2px solid #333;
    border-bottom: 2px solid #333;
    padding: 10px;
}

.user-form-container {
    width: 70%; /* 70% van de container breedte voor het formulier */
    /* background-color: #303336;  */
    padding: 20px;
}

.list-item {
    padding: 1px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f7b446;
    color: white;
    cursor: pointer;
    border-bottom: 1px solid #333; /* Visuele scheiding tussen items */
}

.list-item:hover {
    transform: translateY(-5px);
    background-color: #e6a33c;
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4);
}

.list-item.active {
    background-color: #e6a33c; /* Actieve gebruiker highlight */
    color: white; /* Doornenbal geel */
}

.photos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.photo {
  width: 150px;
  height: 150px;
  margin: 5px;
  object-fit: cover;
}

.popup {
  position: fixed;          /* Positie relatief aan de viewport */
  top: 50%;                 /* Center vertically */
  left: 50%;                /* Center horizontally */
  transform: translate(-50%, -50%); /* Compenseer de exacte midden positie */
  width: auto;              /* Of een specifieke breedte */
  padding: 20px;            /* Padding rondom de tekst */
  background-color: white;  /* Achtergrond kleur van de popup */
  border: 1px solid #ccc;   /* Rand rondom de popup */
  box-shadow: 0px 0px 15px rgba(0,0,0,0.5); /* Schaduw voor een verhoogd effect */
  z-index: 1000;            /* Zorg ervoor dat het boven andere items staat */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Zwart met transparantie */
  z-index: 999;  /* Net onder de popup */
}

.service-requests-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.service-requests-table th, .service-requests-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.service-requests-table th {
  background-color: #f2f2f2;
}

.service-requests-table tr:hover:not(.expanded-row) {
  background-color: #f0f0f0;
}

.spaced-button {
  margin-right: 10px; /* Adjust the value as needed for desired spacing */
}

.spaced-button:last-child {
  margin-right: 0; /* Ensure the last button doesn't have extra margin */
}

.expanded-row {
  background-color: #f7b446;
  color: white;
}

.expanded-row-content {
  max-height: 500;
}

@keyframes App-logo-spin {
  from {
    transform:  rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes move-left-right {
  from {
    transform:  translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes move-up-down {
  from {
    transform:  translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .menu-tile {
    flex: 0 0 100px; /* Kleinere tegels op tablets */
    width: 100px;
    height: 100px;
  }
  .banner {
    height: 80px;
  }
  .banner-logo {
      height: 60px; /* Kleinere logo grootte op kleinere schermen */
  }
  .banner-text h1 {
      /* font-size: 20px;      */
      display: none;
  }
  .banner-text p {
      /* font-size: 14px; */
      display: none;
  }
}

@media (max-width: 480px) {
  .menu-tile {
    flex: 0 0 100%; /* Eén tegel per rij op mobiele telefoons */
    width: calc(90% - 40px);
    /* width: 40px; */
    height: 100px;
  }
}
